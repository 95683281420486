body {
    background-color: #F6FAFF;
}

table {
    font-size: 0.9rem;
}

tbody {
    border-top: 0 #DEE2E6 !important;
}

.fs-1rem {
    font-size: 1rem;
}

.form-control:focus, .form-select:focus, .form-check-input:focus, .btn:focus
,.select2-container--bootstrap-5.select2-container--focus .select2-selection, .select2-container--bootstrap-5.select2-container--open .select2-selection
,.select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus {
    box-shadow: none;
    border-color: dodgerblue;
}

.navbar {
    background-color: #FFF;
}

.navbar-light .navbar-nav .active {
    border-bottom: 0.3rem solid dodgerblue;
}

@media (max-width: 991px) {
    .navbar-light .navbar-nav .active {
        border-bottom: 0rem;
        color: dodgerblue !important;
        font-weight: bold;
    }
}

.nav-icon {
    font-size: 1.5rem;
    margin-right: 0.7rem;
    color: dodgerblue;
}

.nav-title {
    color: dodgerblue !important;
    font-size: 1.4rem;
    font-weight:bold;
}

.nav-title span {
    font-size: 1rem;
}

.screen-title {
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0.05rem 0.7rem;
    border-left: 0.4rem solid #999;
}

.form-label {
    font-weight: bold;
}

.btn .fa-fw {
    font-size: 1rem;
}

.modal-header {
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
}

.modal-title {
    font-weight: bold;
}

.notice-box {
    margin-top: 1rem;
}
.notice-box ul {
    margin-bottom: 0 !important;
}

.toast.alert-success {
    background-color: #2e6930;
    color: #fff;
}